/**
 * 可注册事件：
 * addDetail【add-detail】增加明细按钮点击事件;
 * delDetail【del-detail】删除明细按钮点击事件;
 * rowClick【row-click】明细表格行点击事件;
 * up【up】表格头部上升按钮点击事件;
 * down【down】表格头部下降按钮点击事件;
 *
 * props里面是外界可以传入的属性，基本上事件执行过程中都要调用到
 * */
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide, inject} from 'vue';
import EditTableUtil,{IEditTableDataObj} from "@/components/base_comp/editTable/editTableUtil";
const EditTableHelper = defineComponent({
    name: "EditTable",
    props: {
        parentInst: {
            type: Object,
            default: null
        },
        beganEdit: {//编辑之前
            type: Function, default: async (options:any) => {return true;}
        },
        afterBeganEdit: {//编辑之后
            type: Function, default: async (options:any) => {return true;}
        },
        beforeAdd: {//新增明细之前
            type: Function, default: async (options:any) => {return true;}
        },
        beforeDel: {//删除明细之前
            type: Function, default: async (options:any) => {return true;}
        },
        afterDel: {//删除明细之后
            type: Function, default: async (options:any) => {return true;}
        },
        beforeEndEdit: {//结束编辑之前
            type: Function, default: async (options:any) => {return true;}
        },
        beforeClearData: {//清除数据之前
            type: Function, default: async (options:any) => {return true;}
        },
        afterClearData: {//清除数据之后
            type: Function, default: async (options:any) => {return true;}
        },
        gridLoaded: {//表格数据准备完成
            type: Function, default: async (res:any) => {
                if(res && res.row)res.rows.forEach((row:any)=> {row.EDIT = false;});//加载表格，为表格初始化影藏字段EDIT,默认为false，表示该行现在没有处于编辑状态
            }
        },
    },
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('gridTableOwner', proxy);
        let _detailParam:any=context.attrs.detailParam;
        let dataObj:IEditTableDataObj=reactive<IEditTableDataObj>({
            utilInst:{} as any,
            editParams: {idField: "F_ID",gridTitle:proxy.$t('editTable.title'),canAdd: true,canUp:false,canDown:false,readOnly: false,isShowFixCol: true,dragRow:false,toolBar: [],showTitle:true,showToolBar:true},//明细表格的参数
            fields: [],//表格所有列
            insert: [], //新增的数据
            update: [], //修改的数据
            delete: [], //删除的数据
            detailTypeRef:null,
            detailType: _detailParam.queryParam.detailType//外界调用EditTable的时候，肯定会传入queryParam（表格需要这个对象）,在queryParam里面指定明细表格名称
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new EditTableUtil(proxy,dataObj,props,context);
            //把父组件的参数和自己本身的参数合并
            dataObj.editParams = Object.assign({autoLoad:false,tbId:dataObj.detailType,dragParent:dataObj.detailType},dataObj.editParams,_detailParam);
        })
        onMounted(()=>{
            //表格所有列
            dataObj.fields=dataObj.detailTypeRef.tbCols.fields;
        })
        //表格头部的toolbar自定义按钮事件
        const clickHandler=(ev:any, param:any)=>{
            //父组建有相应事件就调用父组建的事件，没有定义的事件就调用子组建本身定义好的事件
            if (dataObj.utilInst.editTableOwner[ev]) {
                dataObj.utilInst.editTableOwner[ev](param);
            } else {
                proxy[ev](param);
            }
        }
        //设置表格只读
        const setReadOnly=async (val:boolean)=>{
            dataObj.editParams.readOnly=val;
            await dataObj.utilInst.setAllRowCanNotEdit;
        }

        //数据表格加载完毕事件
        const gridLoaded=async(res:any)=>{
            await dataObj.utilInst.gridLoaded(res);
        }
        //表格头部新增按钮处理事件
        const addDetail=async ()=>{
            await dataObj.utilInst.addDetail();
        }
        //表格头部删除按钮处理事件
        const delDetail=async (row:any, index:number)=>{//表格固定列的删除行事件
            await dataObj.utilInst.delDetail(row,index);
        }
        //点击行事件，把其它行变为不可编辑，把当前点击行变为可编辑状态
        const rowClickHandler=async (row:any)=>{
            await dataObj.utilInst.clickTableRowHandler({row:row});
        }
        //清除表格数据
        const clearDetailData=async ()=>{
            await dataObj.utilInst.clearDetailData();
        }
        //上移
        const up=async ()=>{
            await dataObj.utilInst.up();
        }
        //下移
        const down=async ()=>{
            await dataObj.utilInst.down(context);
        }

        //获取表格引用
        const getTbInst=()=>{
            return dataObj.detailTypeRef;
        }
        //得到表格数据
        const getDetailData=()=>{//得到编辑表格数据
            return dataObj.detailTypeRef.tbData;
        }
        //重新加载表格
        const reloadGrid=async(params:any)=>{
            await dataObj.detailTypeRef.queryHandler(true, params);
        }
        //得到将要保存|更新|删除的数据
        const getSaveDetailData=async()=>{
            //先结束整个表格的编辑动作
            if(!await dataObj.utilInst.setAllRowCanNotEdit())return null;
            return {
                insert: [...new Set(dataObj.insert)],//去重 考虑是否需要
                update: [...new Set(dataObj.update)],//去重
                delete: [...new Set(dataObj.delete)]//去重
            };
        }
        //增加一行
        const addRow=(row:any)=>{
            dataObj.detailTypeRef.tbData.push(row);
            dataObj.insert.push(row);
        }
        //把某行放进新增数组中
        const setInsert=(row:any)=>{
            dataObj.insert.push(row);
        }
        //把某行放进修改数组中
        const setUpdate=(row:any)=>{
            dataObj.update.push(row);
        }
        //设置要后台删除行的id
        const setDelete=(id:any)=>{
            dataObj.delete.push(id);
        }
        //更新某行
        const setRowData=(row:any, index:number)=>{
            dataObj.detailTypeRef.tbData[index] = row;
        }
        //更新某行指定列的值
        const setCellData=(field:string,value:any, index:number)=>{
            dataObj.detailTypeRef.tbData[index][field]=value;
        }

        //获取表格当前编辑行
        const getEditRow=()=>{
            let tbData = dataObj.detailTypeRef.tbData;
            return tbData.find((row:any,index:number,tbData:any)=>{
                return tbData[index].EDIT==true;
            });
        }

        return{
            ...toRefs(dataObj),clickHandler,setReadOnly,addDetail,delDetail,gridLoaded,
            rowClickHandler,getDetailData,clearDetailData,reloadGrid,getSaveDetailData,
            addRow,setInsert,setUpdate,setDelete,setRowData,setCellData,getTbInst,getEditRow,up,down
        }
    },

    components: {}
});
export default EditTableHelper;